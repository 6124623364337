.ncssRow {
   vertical-align: baseline;
   height: 48px;
}

.header {
   background-attachment:scroll;
   background-clip:border-box;
   background-color:rgb(17, 17, 17);
   background-image:none;
   background-origin:padding-box;
   background-position-x:0%;
   background-position-y:0%;
   background-size:auto;
   border-bottom-color:rgb(255, 255, 255);
   border-bottom-style:none;
   border-bottom-width:0px;
   border-image-outset:0px;
   border-image-repeat:stretch;
   border-image-slice:100%;
   border-image-source:none;
   border-image-width:1;
   border-left-color:rgb(255, 255, 255);
   border-left-style:none;
   border-left-width:0px;
   border-right-color:rgb(255, 255, 255);
   border-right-style:none;
   border-right-width:0px;
   border-top-color:rgb(255, 255, 255);
   border-top-style:none;
   border-top-width:0px;
   box-sizing:border-box;
   color:rgb(255, 255, 255);
   display:block;
   font-family:"Helvetica Neue", Helvetica, Arial, sans-serif;
   font-size:14px;
   font-stretch:100%;
   font-style:normal;
   font-variant-caps:normal;
   font-variant-east-asian:normal;
   font-variant-ligatures:normal;
   font-variant-numeric:normal;
   font-weight:400;
   height:64px;
   line-height:24px;
   margin-bottom:0px;
   margin-left:0px;
   margin-right:0px;
   margin-top:0px;
   padding-bottom:8px;
   padding-left:8px;
   padding-right:8px;
   padding-top:8px;
   text-size-adjust:100%;
   vertical-align:baseline;
   width: 100%;
   -webkit-font-smoothing:antialiased;
   -webkit-border-image:none;
}

.logo {
   font-size: 20px;
   vertical-align: middle;
   margin-left: 8px;
   margin-right: 16px;
   margin-block-end: 0px;
   margin-block-start: 0px;
}

.u-va-t {
   vertical-align: top;
   height: 48px;
}

.title {
   border-bottom-color:rgb(255, 255, 255);
   border-bottom-style:none;
   border-bottom-width:0px;
   border-image-outset:0px;
   border-image-repeat:stretch;
   border-image-slice:100%;
   border-image-source:none;
   border-image-width:1;
   border-left-color:rgb(255, 255, 255);
   border-left-style:none;
   border-left-width:0px;
   border-right-color:rgb(255, 255, 255);
   border-right-style:none;
   border-right-width:0px;
   border-top-color:rgb(255, 255, 255);
   border-top-style:none;
   border-top-width:0px;
   box-sizing:border-box;
   color:rgb(255, 255, 255);
   display:inline-block;
   font-family:"Nike TG", "Helvetica Neue", Helvetica, Arial, sans-serif;
   font-size:28px;
   font-stretch:100%;
   font-style:normal;
   font-variant-caps:normal;
   font-variant-east-asian:normal;
   font-variant-ligatures:normal;
   font-variant-numeric:normal;
   font-weight:400;
   height:48px;
   letter-spacing:0.5px;
   line-height:48px;
   margin-block-end:0px;
   margin-block-start:0px;
   margin-bottom:0px;
   margin-inline-end:0px;
   margin-inline-start:0px;
   margin-left:0px;
   margin-right:0px;
   margin-top:0px;
   padding-bottom:0px;
   padding-left:0px;
   padding-right:0px;
   padding-top:0px;
   text-size-adjust:100%;
   text-transform:uppercase;
   vertical-align:middle;
   width:205.484px;
   -webkit-font-smoothing:antialiased;
   -webkit-border-image:none;
}

.swooshLogo {
   border-bottom-color:rgb(255, 255, 255);
   border-bottom-style:none;
   border-bottom-width:0px;
   border-image-outset:0px;
   border-image-repeat:stretch;
   border-image-slice:100%;
   border-image-source:none;
   border-image-width:1;
   border-left-color:rgb(255, 255, 255);
   border-left-style:none;
   border-left-width:0px;
   border-right-color:rgb(255, 255, 255);
   border-right-style:none;
   border-right-width:0px;
   border-top-color:rgb(255, 255, 255);
   border-top-style:none;
   border-top-width:0px;
   box-sizing:border-box;
   color:rgb(255, 255, 255);
   display:inline-block;
   font-family:nike-glyphs;
   font-size:20px;
   font-stretch:100%;
   font-style:normal;
   font-variant-caps:normal;
   font-variant-east-asian:normal;
   font-variant-ligatures:normal;
   font-variant-numeric:normal;
   font-weight:400;
   height:24px;
   line-height:24px;
   margin-left:8px;
   margin-right:16px;
   padding-bottom:0px;
   padding-left:0px;
   padding-right:0px;
   padding-top:0px;
   text-decoration-color:rgb(255, 255, 255);
   text-decoration-line:none;
   text-decoration-style:solid;
   text-size-adjust:100%;
   text-transform:none;
   vertical-align:middle;
   width:42.8125px;
   -webkit-font-smoothing:antialiased;
   -webkit-border-image:none;
}
