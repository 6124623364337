z.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(20, 20, 20, 0.65);
  z-index: 75;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

body {
  zoom: 0.85;
  -moz-transform: scale(0.85);
}

div#nav-bar {
  padding: 30px 0 0 0;
  font-family: "Nike TG", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
ul#list-nav {
  font-size: 20px;
  border-bottom: 1px solid #dee2e6;
  width: 100%;
  justify-content: center;
}
li#swoosh-icon {
  position: absolute;
  left: 0;
  bottom: 5px;
}
span#custom-swoosh {
  font-size: 30px;
  margin-left: 50px;
  padding-bottom: 10px;
}

#nav-bar a.active {
  border-bottom: 1px solid black;
  padding-bottom: 25px;
}

/* Subnav container styling */
.appnav {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  margin: 0px;
  padding: 1rem;
  border-bottom: 1px solid #dee2e6;
}

.appnav ul {
  margin: 0;
  padding: 0;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

.appnav a {
  text-decoration: none;
  color: #8d8d8d;
}

.appnav a:hover {
  color: black;
}

/* Dropdown styling */
#nav-bar .dropdown {
  position: static;
}

#nav-bar .dropdown-menu {
  position: absolute;
  border-radius: 0;
  margin: 0;
  border-top: none;
  width: 100%;
  padding: 40px 0px;
  left: 0;
  z-index: 1;
  display: block;
  visibility: hidden;
  opacity: 0;
  transition: opacity .1s .40s, visibility 0ms 90ms;
  -moz-transition: opacity .1s .40s, visibility 0ms 90ms;
  -webkit-transition: opacity .1s .40s, visibility 0ms 90ms;

}

#nav-bar .dropdown:hover .dropdown-menu {
  visibility: visible;
  opacity: 1;
  transition: opacity .1s, visibility 90ms;
  -moz-transition: opacity .1s, visibility 90ms;
  -webkit-transition: opacity .1s, visibility 90ms;
}

#nav-bar .dropdown-border-left {
  border-left: 1px solid #dee2e6;
}

#nav-bar .dropdown-border-right {
  border-right: 1px solid #dee2e6;
}

#nav-bar .dropdown-menu .nav-link,
.dropdown--text {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

#nav-bar .dropdown--text {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #8d8d8d;
}

/* #nav-bar .dropdown-menu .container {
  padding: 40px 0px;
  margin: 30px;
} */

/* #nav-bar .dropdown-menu .nav-link {
  padding: .5rem 1rem !important;
  font-size: 14px !important;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
} */

/* #nav-bar .dropdown-menu .dropdown-menu {
  position: absolute;
  left: 100%;
  top: 0px;
  text-align: left;
  font-size: 14px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
} */
